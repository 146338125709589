<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-1">
								<p class="text-left text-gray-700">
									At {{ $store.state.brandInfo.displayName }} we offer a completely
									<span class="font-semibold">free, unrestricted 10 day trial, no charge, 100% free, no commitment, no cancellation required</span> so that you
									can really test out our service.
								</p>
								<p class="text-left text-gray-700 font-semibold">PLEASE NOTE: YOU MAY REQUIRE A VPN which we do NOT provide</p>
							</div>
							<p><span class="font-semibold">Only 1 trial per customer</span> with no extension so please be sure to sign up when you have time to test us out.</p>
							<hr />
							<p class="font-semibold">
								PLEASE READ THROUGH ALL STEPS CAREFULLY BEFORE YOU BEGIN AND DOUBLE CHECK YOUR SPELLING BEFORE REQUESTING A TRIAL. INVALID, FAKE, MISSPELLED EMAIL
								OR PHONE NUMBERS WILL NOT RECEIVE A TRIAL.
							</p>
							<div class="space-y-1">
								<p>Make sure you have a device from our recommended devices list before you begin! Recommended hardware found in the link below.</p>
							</div>
							<hr />
							<div>
								<router-link :to="{ name: 'WhatHardwareIsRecommended' }"
									><p class="font-semibold hover:text-blue-700">What hardware is recommended?</p></router-link
								>
							</div>
							<div class="font-semibold space-y-2">
								<p>STEP 1:</p>
								<p>
									Visit <a :href="`${$store.state.brandInfo.siteLink}/#trial`" class="hover:text-blue-700">{{ $store.state.brandInfo.siteLink }}</a>
								</p>
							</div>
							<hr />
							<div>
								<div class="pb-5">
									<p class="inline font-semibold bg-yellow-300">Then select start trial.</p>
								</div>
								<a :href="`${$store.state.brandInfo.siteLink}/#trial`"
									><img src="./../../../assets/images/mini-icon/access-to-your-favorite.png" alt="access-to-your-favorite"
								/></a>
							</div>
							<div class="space-y-2">
								<p class="font-semibold">STEP 2:</p>
								<div class="pb-5">
									<p class="inline font-semibold bg-yellow-300">You will be taken to screen below</p>
								</div>
								<img src="./../../../assets/images/mini-icon/create-new-trial-account.png" alt="access-to-your-favorite" />
								<p class="py-5">
									Carefully fill in your <span class="font-semibold bg-yellow-300">NAME, VALID EMAIL ADDRESS, and VALID MOBILE PHONE NUMBER</span> in the form.
								</p>
								<p>
									<span class="font-semibold">IMPORTANT:</span> You MUST use a <span class="font-semibold">VALID EMAIL AND PHONE NUMBER</span> to receive an SMS
									text CODE to your mobile phone. Double-Check that you have entered in your email address and phone number correctly because you will not receive
									trial due to it going to incorrect email/phone number.
								</p>
								<p class="font-semibold py-5">Once you have verified and made sure that all information is correctly filled out then click submit.</p>
							</div>
							<div class="space-y-1">
								<p class="font-semibold">STEP 3:</p>
								<p class="font-semibold underline">IMPORTANT: DO NOT CLOSE THE NEXT PAGE BELOW.</p>
								<div>
									<p class="font-semibold bg-yellow-300 inline">KEEP IT OPEN.</p>
								</div>
								<div class="pt-5">
									<p class="font-semibold inline bg-yellow-300">
										Check your mobile phone for an SMS text code. Code is only valid for 30 seconds so be sure to have your mobile phone with you
									</p>
								</div>
							</div>
							<div style="margin-top: 40px" class="space-y-6">
								<p class="font-semibold inline bg-yellow-300">NOTE: THIS MOBILE SMS TEXT NUMBER CODE IS NOT YOUR LOGIN PASSWORD.</p>
								<img src="./../../../assets/images/mini-icon/phone-verification.png" alt="" />
								<p>STEP 4:</p>
								<div>
									<p class="font-semibold bg-yellow-300 inline">-Check your email for new trial login information.</p>
								</div>
								<div class="space-y-5">
									<p class="font-semibold inline bg-yellow-300">
										Email can take up to 10 minutes to arrive. Please refresh your mailbox and check your Junk and Spam mail folders if needed.
									</p>
									<img src="./../../../assets/images/mini-icon/your-trail-is-ready.png" alt="your-trail-is-ready" />
									<p class="font-semibold">
										If you encounter any issue please email us at {{ $store.state.brandInfo.supportEmail }} to request a trial and include all information below
									</p>
									<ul>
										<li class="font-semibold bg-yellow-300 inline">1. Where are you from? <br /></li>
										<li class="font-semibold bg-yellow-300 inline">2. What is your Phone Number? (country code as well)<br /></li>
										<li class="font-semibold bg-yellow-300 inline">
											3. Run our <a :href="$store.state.brandInfo.speedTestLink">{{ $store.state.brandInfo.speedTestLink }}</a> and send us screenshot to be
											sure we're able to provide you a proper service.
										</li>
									</ul>
									<p class="font-semiboild">-Download compatible apps on your preferred device - visit here for setup and installation guides.</p>
									<div class="font-semibold">
										<p class="font-semiboild">-We recommend certified android tv box from our recommended device list for best experience.</p>
										<p class="text-lg hover:text-blue-500">
											<router-link :to="{ name: 'WhatHardwareIsRecommended' }">What hardware is recommended?</router-link>
										</p>
									</div>
									<div class="font-semibold">
										<p>Please note you may require a VPN.</p>
										<p>Download, login & enjoy.</p>
									</div>
								</div>
							</div>
							<div class="text-center py-10">
								<router-link :to="{ name: 'Home' }" class="py-4 px-8 text-lg border-2 border-green-500 rounded-full hover:bg-green-500 hover:text-white"
									>Help Center</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Free10DayTrial',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
